(() => {
  const handleEvent = (email) => {
    HockeyStack.goal('Marketo Form Submitted');
    if (email) HockeyStack.identify(email);
  }

  window.addEventListener('message', event => {
    try {
      const formId = JSON.parse(event.data)?.mktoResponse?.data?.formId;
      if (typeof window.MktoForms2 !== 'undefined') {
        const formVals = window.MktoForms2.getForm(formId).getValues();
        const email = formVals?.Email || formVals?.email;

        if (typeof window.HockeyStack !== 'undefined') return handleEvent(email);
        if (!Array.isArray(window.hockeystackQueue)) window.hockeystackQueue = [];
        window.hockeystackQueue.push(() => handleEvent(email));
      }
    } catch (err) {}
  });
})();
